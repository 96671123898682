// user.actions.ts

import { createAction, props, union } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';

export enum UserActionTypes {
    UserInit = '[User] Init',
    UserLoadOne = '[User] Load One',
    UserUpdateOne = '[User] Update One',
    UserUpdateOneSuccess = '[User] Update One Success',
    UserLoadOneSuccess = '[User] Load One Success',
    UserLoadOneError = '[User] Load One Error',
    UserLoadListByCheckIn= '[User] Load List By Check In',

    UserLoadList = '[User] Load List',
    UserLoadListSuccess = '[User] Load List Success',
    UserLoadListError = '[User] Load List Error',
    UserLoadListByLatLng ='[User] Load List By Lat Lng',
}

export const userUpdateOne = createAction(
    UserActionTypes.UserUpdateOne,
    props<{ uid: string, dataToUpdate:{[key:string]: any} }>(),
);

export const userLoadOne = createAction(
    UserActionTypes.UserLoadOne,
    props<{ uid: string }>(),
);


export const userLoadOneSuccess = createAction(
    UserActionTypes.UserLoadOneSuccess,
    props<{ user: GthUserModel }>(),
);

export const userUpateSuccess = createAction(
    UserActionTypes.UserUpdateOneSuccess,
    props<{ user?: GthUserModel }>(),
);

export const userLoadOneError = createAction(
    UserActionTypes.UserLoadOneError,
    props<{ error: unknown }>(),
);
export const userLoadList = createAction(
    UserActionTypes.UserLoadList,
    props<{ uids: string[] }>(),
);
export const userLoadListSuccess = createAction(
    UserActionTypes.UserLoadListSuccess,
    props<{ users: GthUserModel[]}>(),
);
export const userLoadListError = createAction(
    UserActionTypes.UserLoadListError,
    props<{ error: unknown }>(),
);
export const userLoadListByLatLng = createAction(
    UserActionTypes.UserLoadListByLatLng,
    props<{ lat: number, lng: number, bounds?: google.maps.LatLngBoundsLiteral }>(),
);
export const userLoadListByCheckIn = createAction(
    UserActionTypes.UserLoadList,
    props<{ placeId: string }>(),
);


export const allUsers = union({
    userLoadOne,
    userUpdateOne,
    userUpateSuccess,
    userLoadOneSuccess,
    userLoadOneError,
    userLoadList,
    userLoadListSuccess,
    userLoadListError,
    userLoadListByCheckIn,
  });

export type UserActionsUnion = typeof allUsers;
